// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Header from "./Components/Header/Header";
// import AnnouncementBar from "./Components/AnnouncementBar/AnnouncementBar";
// import Home from "./Pages/Home/Home";
// import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
// import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
// import "./App.scss";
// import Footer from "./Components/Footer/Footer";

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <AnnouncementBar />
//         <Header />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//           <Route
//             path="/terms-and-conditions"
//             element={<TermsAndConditions />}
//           />
//         </Routes>
//         <Footer />
//       </div>
//     </Router>
//   );
// }

// export default App;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Components/Header/Header";
import AnnouncementBar from "./Components/AnnouncementBar/AnnouncementBar";
import Home from "./Pages/Home/Home";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import "./App.scss";
import Footer from "./Components/Footer/Footer";

function App() {
  useEffect(() => {
    function formatPhoneNumber(number) {
      number = number.replace(/[^\d]/g, "");
      if (number.length === 11 && number.charAt(0) === '1') {
        return `+1 (${number.substring(1, 4)}) ${number.substring(4, 7)}-${number.substring(7)}`;
      } else if (number.length === 10) {
        return `+1 (${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
      }
      return number; 
    }
  
    function formatTelLinks() {
      const telLinks = document.querySelectorAll("a[href^='tel:']");
  
      telLinks.forEach((link) => {
        let phoneNumber = link.getAttribute("href").replace("tel:", "");
        let formattedNumber = formatPhoneNumber(phoneNumber);
        link.setAttribute("href", "tel:" + formattedNumber);
      });
    }
    formatTelLinks();
  }, []); 
  
  
  

  return (
    <Router>
      <div className="App">
        <AnnouncementBar />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
