import React, { useState, useEffect } from "react";
import "./Footer.scss";
import Logo from "../../images/logo.svg";
import FooterComp from "../FooterComp/FooterComp";
import { Link } from "react-router-dom";
import getWindowHostName from "../../hooks/useWindowHostname";
const Footer = () => {
  const [HeaderTitle, setHeaderTitle] = useState("American <span>Extra Help</span>");

  useEffect(() => {
    const hostName = getWindowHostName().toLowerCase();

    const domainHeaders = {
      "enrollintomedicare.com": "Enroll Into <span>Medicare</span>",
      "QualifyMedicareSavings.com" : "Qualify Medicare<span>Savings</span>",
      "MedicareCostAdvisors.com" : "Medicare <span>Cost Advisors</span>",
      "AmericanAdvantageBenefits.com": "<span>American</span><span>Advantage</span><span>Benefits</span>",
      "AmericanPlanCompare.com": "American <span>Plan Compare</span>",
      "HealthisWealthMedicare.com": "Healthis Wealth<span>Medicare</span>",
      "SeniorUnitedResources.com": "Senior United<span>Resources</span>",
      "SpendingAllowanceBenefits.com": "Spending Allowance<span>Benefits</span>",
      "americanextrahelp.com": "American <span>Extra Help</span>",

    }

    if(domainHeaders[hostName]) {
      setHeaderTitle(domainHeaders[hostName]);
    }
    else {
      setHeaderTitle("American <span>Extra Help</span>");
    } 
  }, [])
  return (
    <div>
      <FooterComp />
      <section className="footer">
        <div className="footer__logo">
          <div className="footer__logo-main">
            <Link to="/" onClick={() => window.scrollTo({ top: 0 })}>
              <figure>
                <img src={Logo} alt="" />
              </figure>
              <div>
                <h1 dangerouslySetInnerHTML={{ __html: HeaderTitle }}>
                 
                </h1>
                <p>Non-Government Entity</p>
              </div>
            </Link>
          </div>
          <div className="footer__logo-company">
            <p>Connect with a Licensed Insurance Agent</p>
            <p>
              <a href="tel:6408886518">(640) 888-6518</a>
            </p>
            <p>TTY Users Dial 711</p>
          </div>
        </div>
        <div className="container">
          <div className="footer__desc">
            <div className="footer__desc-one">
              <p>
                The following disclaimer applies to our national offering: We do
                not offer every plan available in your area. Currently we
                represent 5 organizations which offer 2082 products in your
                area. Please contact Medicare.Gov , 1-800-MEDICARE, or your
                local State Health Insurance Program (SHIP)to get information on
                all of your options.
              </p>
              <p>
                To request plan information without providing personal
                information, please call to speak with a licensed insurance
                agent at the phone number. We are not affiliated with any plan
                or endorsed by any government entity or agency. This site is
                operated in partnership with Health is Wealth Marketing LLC, a
                licensed insurance agency.
              </p>
            </div>
            <div className="footer__desc-other">
              <p>
                Participating sales agencies represent plans that are insured or
                covered by a Medicare Advantage PDP, HMO, PPO, or PFFS
                organization with a Medicare Contract and/or a Medicare-approved
                Part D Sponsor. Enrollment in plans depends on contract renewal.
                Not all plans offer all of these benefits. Benefits may vary by
                carrier and location.
              </p>
              <p>
                Limitations and exclusions may apply. $0 premium plans are not
                available in all areas. Enrollees must continue to pay their
                Medicare Part B Premium. Availability varies by carrier and
                location. Deductibles, copay, and coinsurance may apply.
              </p>
              <p>
                Members may receive a monthly or quarterly allowance in the form
                of a benefits prepaid card to pay for a wide range of approved
                [groceries] [and] [utilities]. Unused amounts will expire at the
                end of the month or quarter Allowance amounts cannot be combined
                with other benefit allowances.
              </p>
              <p>
                *[Carrier 1],[Carrier 2] offer benefit(s) mentioned that may be
                part of a special supplemental program for chronically ill
                members with one of the following conditions: Diabetes mellitus,
                Cardiovascular disorders, Chronic and disabling mental health
                conditions, Chronic lung disorders, Chronic heart failure. This
                is not a complete list of qualifying conditions. Having a
                qualifying condition alone does not mean you will receive the
                benefit(s). Other requirements may apply.
              </p>
              <p>
                Products and services are provided exclusively by our partners,
                but not all offer the same plans or options. Descriptions are
                for informational purposes only and subject to change. We
                encourage you to shop around and explore all of your options.
              </p>
              <p>MULTIPLAN_HIWMLLCOCA1LP22025_YRU_ST_M</p>
            </div>
          </div>
        </div>
      </section>
      <div className="copy-right">
        <span>Health is Wealth Marketing LLC</span>
        <div className="vr mob-hide"></div>
        <div className="mob-flex">
          <span>
            <Link
              to="/privacy-policy"
              onClick={() => window.scrollTo({ top: 0 })}
            >
              Privacy Policy
            </Link>
          </span>
          <div className="vr"></div>
          <span>
            <Link
              to="/terms-and-conditions"
              onClick={() => window.scrollTo({ top: 0 })}
            >
              Terms & Conditions
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};
export default Footer;
