import React, { useState, useEffect } from "react";
import "./Header.scss";
import Logo from "../../images/logo.svg";
import HeaderClock from "../../images/header-clock.svg";
import { Link } from "react-router-dom";
import getWindowHostName from "../../hooks/useWindowHostname";
const Header = () => {
  console.log("Latest pull")
  const [HeaderTitle, setHeaderTitle] = useState("American <span>Extra Help</span>");

  useEffect(() => {
    const hostName = getWindowHostName().toLowerCase();

    const domainHeaders = {
      "enrollintomedicare.com": "Enroll Into <span>Medicare</span>",
      "QualifyMedicareSavings.com" : "Qualify Medicare<span>Savings</span>",
      "MedicareCostAdvisors.com" : "Medicare <span>Cost Advisors</span>",
      "AmericanAdvantageBenefits.com": "American Advantage<span>Benefits</span>",
      "AmericanPlanCompare.com": "American <span>Plan Compare</span>",
      "HealthisWealthMedicare.com": "Healthis Wealth<span>Medicare</span>",
      "SeniorUnitedResources.com": "Senior United<span>Resources</span>",
      "SpendingAllowanceBenefits.com": "Spending Allowance<span>Benefits</span>",
      "americanextrahelp.com": "American <span>Extra Help</span>",
    }

    if(domainHeaders[hostName]) {
      setHeaderTitle(domainHeaders[hostName]);
    }
    else {
      setHeaderTitle("American <span>Extra Help</span>");
    }
  }, [])
  
  return (
    <header className="container">
      <div className="wrapper">
        <Link to="/">
          <div className="logo">
            <figure>
              <img src={Logo} alt="" />
            </figure>

            <div className="header__details">
              <h2 dangerouslySetInnerHTML={{ __html: HeaderTitle }}>
                
              </h2>
              <p>Non-Government Entity</p>
            </div>
          </div>
        </Link>
        <div className="business-timing">
          <figure>
            <img src={HeaderClock} alt="" />
          </figure>
          <div>
            <p>Monday - Friday 8AM - 6PM</p>
            <p>Central Time TTY:711</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
