import React from "react";
import "./ChooseUs.scss";
import CreditCard from "../../images/SpendingAllowance.png";
import CallIcon from "../../images/white-call.svg";
import ChoosUsData from "../../apis/choos-us-data";
const ChooseUs = () => {
  return (
    <section className="choose-us">
      <div className="choose-us__title">
        <h1>More Reasons To Choose Us</h1>
        <p>We put your needs at the heart of everything we do.</p>
      </div>
      <div className="container">
        <div className="wrapper">
          <div className="choose-us__credit-card">
            <figure>
              <img src={CreditCard} alt="" />
            </figure>

            <p>
              <strong>With a simple phone call,</strong>&nbsp;you can easily and
              quickly compare Medicare Advantage plans
            </p>
            <div className="review-link">
              <a href="tel:6408886518">
                <figure>
                  <img src={CallIcon} alt="" />
                </figure>
                Call Toll Free (640) 888-6518
              </a>
            </div>
          </div>

          <div className="choose-us__list">
            {ChoosUsData?.map((data) => (
              <div className="choose-us__list-card" key={data?.id}>
                <h3>{data?.header}</h3>
                <p>{data?.text}</p>

                <figure>
                  <img src={data?.image} alt="" />
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
